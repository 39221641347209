StateURL.onParamChange('subpage', function(value) {
	if (typeof value != 'undefined') {
	    showOnlyElementsWithAttributeXMatchingY('js-subpage', value);
      showOnlyElementsWithAttributeXMatchingY('subpage-id', value);
	}

	$('[js-main-scroll-area]').scrollTo(0);
});

$.On('click', '[js-go-to-subpage]', function() {
	var targetSubpage = $(this).attr('js-go-to-subpage');
  StateURL.set('subpage', targetSubpage);
});

$.On('click', '[js-action-go-back]', function() {
  window.history.back();
});

State.setDefault('user-logged-in', 'false');
State.onChange('user-logged-in', function(value) {
  $('[js-show-when-logged-in]').isShownWhen(value === 'true');
  $('[js-show-when-not-logged-in]').isShownWhen(value === 'false');
});

StateURL.onChange('loggedIn', function(value) {
  if (value === "true") {
    State.set('user-logged-in', 'true');
    StateURL.remove('loggedIn', {doNotCreateHistoryState: true});
  }
});

$.On('click', '[js-action-log-out]', function() {
  State.set('user-logged-in', 'false');
  window.location.href = "/homepage"; //TODO create logout page
});

var navMap = {
  'dashboard': ['dashboard'],
  'cars': ['cars'],
  'settings': ['settings'],
};

var subpagesWithoutSidebar = ['projects', 'account-settings', 'organization-settings'];

StateURL.onChange('subpage', function(value) {
  $('[js-sidebar-nav] [js-go-to-subpage]').each(function() {
    var attr = $(this).attr('js-go-to-subpage');
    var isInNavMapArray = $.inArray(value, navMap[attr]);
    $(this).addClassWhen(isInNavMapArray >= 0, 'is-current');
    $(this).find('[js-nav-icon]').addClassWhen(isInNavMapArray >= 0, 'is-current');
  });

  $('[js-right-pane]').addClassWhen(subpagesWithoutSidebar.includes(value), 'is-full-width');
  $('[js-selector="sidebar"]').isShownWhen(subpagesWithoutSidebar.includes(value) !== true);

  $('[js-nav]').each(function() {
    var attr = $(this).attr('js-nav');
    $(this).addClassWhen(value === attr, 'is-current');
  });
});

StateURL.onChange('subpage', function(value) {
  if (value === 'suites' || value === 'tests') {
    $('[js-subpage-main-preloader]').removeClass('is-hidden');
    delay(300, function() {
      $('[js-subpage-main-preloader]').addClass('is-hidden');
    });
  }
});

// //SPA navigation tabs -- currently unused, kept just in case
// StateURL.onParamChange('mainTab', function(value) {
// 	if (typeof value != 'undefined') {
// 		$('[main-tab-id]').not('[main-tab-id="'+value+'"]').removeClass('is-current');
// 		$('[main-tab-id="'+value+'"]').addClass('is-current');

// 	    $('[secondary-navbar-id]').not('[secondary-navbar-id="'+value+'"]').addClass('is-hidden');
// 	    $('[secondary-navbar-id="'+value+'"]').removeClass('is-hidden');
// 	}
// });

// $.On('click', '[main-tab-id]', function(event) {
// 	var valueToSet = $(event.currentTarget).attr('main-tab-id');
// 	StateURL.setParam('mainTab', valueToSet);

// 	//clicking on tab should load the first subtab content
// 	var relatedSecondaryNavbarId = $(event.currentTarget).attr('main-tab-id');
// 	var firstSecondaryTab = $('[secondary-navbar-id="'+relatedSecondaryNavbarId+'"]').find('[secondary-tab-id]').first();
// 	var subpageId = firstSecondaryTab.attr('secondary-tab-id');
// 	StateURL.setParam('subpage', subpageId, {doNotCreateHistoryState: true});
// 	StateURL.setParam('secondaryTab', subpageId, {doNotCreateHistoryState: true});
// });

// $.On('click', '[secondary-tab-id]', function(event) {
// 	var valueToSet = $(event.currentTarget).attr('secondary-tab-id');
// 	setTimeout(function() {
// 		StateURL.setParam('secondaryTab', valueToSet, {doNotCreateHistoryState: true});
// 	}, 1);
// 	StateURL.setParam('subpage', valueToSet);
// });

// StateURL.onParamChange('secondaryTab', function(value) {
// 	$('[secondary-tab-id]').not('[secondary-tab-id="'+value+'"]').removeClass('is-current');
// 	$('[secondary-tab-id="'+value+'"]').addClass('is-current');
// });

$.On('preloadingComplete', function() {
  $('[js-scroll-shadow]').each(function() {
    var attr = $(this).attr('js-scroll-shadow');
    var header = $('[js-scroll-shadow-header="'+attr+'"]');
    var area = $(this);
    header.addClassWhen(area.scrollTop() > 0, 'is-with-scroll-shadow');
    $(this).on('scroll', function() {
      header.addClassWhen(area.scrollTop() > 0, 'is-with-scroll-shadow');
    });
  });
})
