function checkIfSubpageIsMatrixCalculator() {
  return window.location.pathname.includes('matrix-calculator') && !window.location.pathname.includes('matrix-calculator-list')
}

StateURL.onChange('modalContent', function(value) {
  if (value !== 'matrix-calculator-how-it-works') {
    var youtubeIframe = $('[js-matrixcalc-video-info-in-modal] iframe');
    var src = youtubeIframe.attr('src');
    youtubeIframe.attr('src', '');
    youtubeIframe.attr('src', src);
  }
});

if (checkIfSubpageIsMatrixCalculator()) {

  $.On.clickElementWithAttribute('js-matrixcalc-set-salary-type', function(attrValue) {
    StateURL.set('salary-type', attrValue, {doNotCreateHistoryState: true});
  });

  StateURL.setDefault('salary-type', 'b2b', {doNotCreateHistoryState: true});

  StateURL.onChange('salary-type', function(value) {
    showOnlyElementsWithAttributeXMatchingY('js-matrixcalc-result-row', value);
    addClassToElementsWithAttributeXMatchingY('js-matrixcalc-set-salary-type', value, 'is-current');
  });


  var MATRIXCALC_LEVELS = {
    'junior-1': {
      name: "Junior 1",
      minPoints: 18,
    },
    'junior-2': {
      name: "Junior 2",
      minPoints: 23,
    },  
    'regular-1': {
      name: "Regular 1",
      minPoints: 28,
    },
    'regular-2': {
      name: "Regular 2",
      minPoints: 32,
    },
    'regular-3': {
      name: "Regular 3",
      minPoints: 36,
    },
    'senior-1': {
      name: "Senior 1",
      minPoints: 39,
    },
    'senior-2': {
      name: "Senior 2",
      minPoints: 42,
    },
    'senior-3': {
      name: "Senior 3",
      minPoints: 45,
    },
    'senior-4': {
      name: "Senior 4",
      minPoints: 48,
    },
  }

  var MATRIXCALC_QUESTIONS = [
    'tech-1',
    'tech-2',
    'tech-3',
    'tech-4',
    'tech-5',
    'soft-1',
    'soft-2',
    'soft-3-team-leader',
    'soft-3-open-source',
    'soft-4',
    'soft-5',
  ];

  $.each(MATRIXCALC_QUESTIONS, function(index, itemValue) {
    State.onChange(itemValue, function(value) {
      var thisBlock = $('[js-matrixcalc-skill-block="'+itemValue+'"]');
      thisBlock.find('[js-matrixcalc-skills-desc-level]').each(function() {
        var $this = $(this);
        var attrVal = $this.attr('js-matrixcalc-skills-desc-level');
        if (attrVal == value) {
          $this.isShown();
        } else {
          $this.isHidden();
        }
      });
      if (isNotEmpty(value) && isNumeric(value)) {
        calculateTotalPoints();
        StateURL.set(itemValue, value, {doNotCreateHistoryState: true});
      }
    });
  });

  var MATRIXCALC_PRESETS = {
    'intern': {
      'tech-1': 1,
      'tech-2': 1,
      'tech-3': 1,
      'tech-4': 1,
      'tech-5': 1,
      'soft-1': 1,
      'soft-2': 1,
      'soft-3-team-leader': 1,
      'soft-3-open-source': 1,
      'soft-4': 1,
      'soft-5': 1,
    },
    'junior-1': {
      'tech-1': 1,
      'tech-2': 2,
      'tech-3': 2,
      'tech-4': 2,
      'tech-5': 2,
      'soft-1': 2,
      'soft-2': 1,
      'soft-3-team-leader': 1,
      'soft-3-open-source': 1,
      'soft-4': 2,
      'soft-5': 3,
    },
    'junior-2': {
      'tech-1': 2,
      'tech-2': 2,
      'tech-3': 3,
      'tech-4': 2,
      'tech-5': 2,
      'soft-1': 2,
      'soft-2': 3,
      'soft-3-team-leader': 1,
      'soft-3-open-source': 1,
      'soft-4': 3,
      'soft-5': 3,
    },
    'regular-1': {
      'tech-1': 3,
      'tech-2': 3,
      'tech-3': 3,
      'tech-4': 3,
      'tech-5': 3,
      'soft-1': 3,
      'soft-2': 3,
      'soft-3-team-leader': 1,
      'soft-3-open-source': 1,
      'soft-4': 3,
      'soft-5': 3,
    },
    'regular-2': {
      'tech-1': 3,
      'tech-2': 3,
      'tech-3': 3,
      'tech-4': 3,
      'tech-5': 3,
      'soft-1': 3,
      'soft-2': 4,
      'soft-3-team-leader': 3,
      'soft-3-open-source': 3,
      'soft-4': 3,
      'soft-5': 4,
    },
    'regular-3': {
      'tech-1': 4,
      'tech-2': 4,
      'tech-3': 3,
      'tech-4': 4,
      'tech-5': 3,
      'soft-1': 3,
      'soft-2': 4,
      'soft-3-team-leader': 3,
      'soft-3-open-source': 3,
      'soft-4': 4,
      'soft-5': 4,
    },
    'senior-1': {
      'tech-1': 4,
      'tech-2': 4,
      'tech-3': 4,
      'tech-4': 4,
      'tech-5': 4,
      'soft-1': 4,
      'soft-2': 4,
      'soft-3-team-leader': 3,
      'soft-3-open-source': 3,
      'soft-4': 4,
      'soft-5': 4,
    },
    'senior-2': {
      'tech-1': 5,
      'tech-2': 4,
      'tech-3': 5,
      'tech-4': 4,
      'tech-5': 4,
      'soft-1': 4,
      'soft-2': 4,
      'soft-3-team-leader': 3,
      'soft-3-open-source': 3,
      'soft-4': 4,
      'soft-5': 5,
    },
    'senior-3': {
      'tech-1': 5,
      'tech-2': 4,
      'tech-3': 5,
      'tech-4': 4,
      'tech-5': 4,
      'soft-1': 4,
      'soft-2': 5,
      'soft-3-team-leader': 4,
      'soft-3-open-source': 4,
      'soft-4': 5,
      'soft-5': 5,
    },
    'senior-4': {
      'tech-1': 5,
      'tech-2': 5,
      'tech-3': 5,
      'tech-4': 5,
      'tech-5': 4,
      'soft-1': 5,
      'soft-2': 5,
      'soft-3-team-leader': 4,
      'soft-3-open-source': 4,
      'soft-4': 5,
      'soft-5': 5,
    },
  }

  function calculateTotalPoints() {
    var totalPoints = 0;
    totalPoints = totalPoints + State.get('tech-1');
    totalPoints = totalPoints + State.get('tech-2');
    totalPoints = totalPoints + State.get('tech-3');
    totalPoints = totalPoints + State.get('tech-4');
    totalPoints = totalPoints + State.get('tech-5');  
    totalPoints = totalPoints + State.get('soft-1');
    totalPoints = totalPoints + State.get('soft-2');
    var higherFromSoft3 = Math.max(State.get('soft-3-open-source'), State.get('soft-3-team-leader'))
    totalPoints = totalPoints + higherFromSoft3;
    totalPoints = totalPoints + State.get('soft-4');
    totalPoints = totalPoints + State.get('soft-5');
    if (!isNaN(totalPoints)) {
      State.set('totalPoints', totalPoints);
    } else {
      State.remove('totalPoints');
    }
    //console.log("Total points - "+totalPoints);
  }

  State.onChange('totalPoints', function(value) {
    if (isNotEmpty(value)) {
      $('[js-matrixcalc-result-points]').text(value);
      calculateResultLevel();
      updateMatrixcalTableResult();
    }
  });

  function updateMatrixcalTableResult() {
    $('[js-matrixcalc-table-result-row]').each(function() {
      var thisAttr = $(this).attr('js-matrixcalc-table-result-row');
      var points = State.get(thisAttr);
      $(this).find('[js-matrixcalc-table-result-points]').text(points);
    });
  }

  function calculateResultLevel() {

    var totalPoints = State.get('totalPoints');
    //currently the lowest level we support in the calculator is junior-1
    //TODO handle what happens if your level is below the minimum treshhold for junior-1
    var result = 'junior-1';
    if (!isNaN(totalPoints)) {
      $.each(MATRIXCALC_LEVELS, function(key, value) {
        if (value.minPoints <= totalPoints) {
          result = key;
        }
      });
      State.set('result', result);
    }
  }

  function calculateSalaryPowerBar() {
    var minB2B = $('[js-matrixcalc-result-money-min-b2b]').first().text().replace(/\s+/g, '');
    var maxB2B = $('[js-matrixcalc-result-money-max-b2b]').first().text().replace(/\s+/g, '');
    var resultLevel = State.get('result');
    var currenSalaryB2B = $('[js-matrixcalc-result-row="b2b"] [js-matrix-result-'+resultLevel+']').first().text().replace(/\s+/g, '');
    var percentage = (currenSalaryB2B - minB2B)*100/(maxB2B - minB2B);
    //only calculating bar based on B2B for now
    $('[js-salary-powerbar="b2b"]').css('width', percentage+"%");
    $('[js-salary-powerbar="uop"]').css('width', percentage+"%");
  }

  State.onChange('result', function(value) {
    $('[js-matrix-result-intern]').isShownWhen(value === 'intern');
    $('[js-matrix-result-junior-1]').isShownWhen(value === 'junior-1');
    $('[js-matrix-result-junior-2]').isShownWhen(value === 'junior-2');
    $('[js-matrix-result-regular-1]').isShownWhen(value === 'regular-1');
    $('[js-matrix-result-regular-2]').isShownWhen(value === 'regular-2');
    $('[js-matrix-result-regular-3]').isShownWhen(value === 'regular-3');
    $('[js-matrix-result-senior-1]').isShownWhen(value === 'senior-1');
    $('[js-matrix-result-senior-2]').isShownWhen(value === 'senior-2');
    $('[js-matrix-result-senior-3]').isShownWhen(value === 'senior-3');
    $('[js-matrix-result-senior-4]').isShownWhen(value === 'senior-4');
    $('[js-matrixcalc-result-level]').text(MATRIXCALC_LEVELS[value].name);

    $('[js-matrixcalc-set-preset]').each(function() {
      var thisAttr = $(this).attr('js-matrixcalc-set-preset');
      $(this).addClassWhen(thisAttr ===  value, 'is-current');
      $(this).find('[js-preset-item-active]').isShownWhen(thisAttr === value);
    });

    calculateSalaryPowerBar();
  });

  function setMatrixCalcPreset(presetLevel) {
    $.each(MATRIXCALC_PRESETS[presetLevel], function(key, value) {
      State.set(key, value);
    });
  }

  $.On('preloadingComplete', function() {
    $.each(MATRIXCALC_QUESTIONS, function(index, value) {
      var fromURL = StateURL.get(value);
      if (isNotEmpty(fromURL)) {
        State.set(value, Number(fromURL));
      } else {
        State.set(value, 3);
      }
    });
  });

  $.On.clickElementWithAttribute('js-matrixcalc-set-preset', function(attrValue) {
    setMatrixCalcPreset(attrValue);
  });

}




