/*====================================================================
=            Global variables, environment variables etc.            =
====================================================================*/

//var API_HOST = 'https://api.example.com';
//API_HOST = 'http://localhost:4000'; //comment before merge!!!!!!!!!!
//PROD_ROOT_DOMAIN defined on top of index.html


/*=====  End of Global variables, environment variables etc.  ======*/


/////////////////////////////////